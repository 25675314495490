<template>
  <v-app id="app" light>
    <Header />
    <!-- <v-main> -->
      <LoadingOverlay />
      <!-- Auth Modals -->
      <Login
        v-if="$store.state.loginModal"
        :dialog.sync="$store.state.loginModal"
      />
      <ForgotPassword
        v-if="$store.state.forgotPasswordModal"
        :dialog.sync="$store.state.forgotPasswordModal"
      />
      <SearchTalents
        v-if="$store.state.searchModal"
        :dialog.sync="$store.state.searchModal"
      />
      <v-fade-transition hide-on-leave>
        <router-view :key="$route.path" />
      </v-fade-transition>
    <!-- </v-main> -->
    <Footer v-if="showFooter" />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

// components
import Footer from '@/components/layouts/Footer.vue'
import SearchTalents from '@/components/generic/SearchTalents.vue'

export default {
  name: 'App',
  components: {
    Footer,
    SearchTalents,
  },
  computed: {
  },
  data() {
    return {
      pageTitle: '',
      showHeader: false,
      showFooter: false,
      routes: [
        '/signup',
        '/talent/login',
        '/talent/forgot-password',
        '/talent/email-confirmation',
        '/talent/dashboard',
        '/talent/sessions',
        '/talent/availability',
        '/talent/profile',
        '/talent/appointments',
        '/talent/help',
        '/admin/dashboard',
        '/admin/sessions',
        '/admin/hosts',
        '/admin/users',
      ],
    }
  },
  watch: {
    $route(route) {
      this.$apolloProvider.defaultClient.cache.data.clear()
      this.showHeader = !this.routes.includes(route.path)
      this.showFooter = !this.routes.includes(route.path)
      console.log("app..." + this.$route.name)
      if (this.$route.name == 'Home') { this.$store.commit('pageTitle', '') }
      if (this.$route.name == 'AccountSettings') { this.$store.commit('pageTitle', 'Settings') }
      if (this.$route.name == 'UpcomingSessions') { this.$store.commit('pageTitle', 'Sessions') }
    },
  },
  mounted() {
    this.$setupPusherEvent()
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/style.scss';
#app {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>

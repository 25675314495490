<template>
  <div class="text-center">
    <v-system-bar height="65" dark color="primary">
      <router-link to="/" class="black--text text-decoration-none">
        <v-img
          class="mx-2"
          src="@/assets/images/logo/with-white.png"
          max-height="80"
          max-width="80"
          contain
        ></v-img>
      </router-link>

      <v-spacer></v-spacer>

      <NotificationButton v-if="isLoggedIn && !isAdmin" />

      <!-- <v-avatar v-if="isLoggedIn">
        <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
      </v-avatar> -->

      <v-avatar v-if="isLoggedIn">
        <v-img :src="profileImage" />
      </v-avatar>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab dark color="primary" v-bind="attrs" v-on="on">
            <v-icon dark> mdi-format-list-bulleted-square </v-icon>
          </v-btn>
        </template>

        <!-- Loggedin as admin-->
        <v-list v-if="isLoggedIn && isAdmin">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/dashboard">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Admin Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/hosts">
            <v-list-item-icon>
              <v-icon>mdi-monitor-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hosts</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/users">
            <v-list-item-icon>
              <v-icon>mdi-monitor-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <!-- Loggedin as host-->
        <v-list v-else-if="isLoggedIn && isTalent">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item @click="searchModal()">
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Search</v-list-item-title>
          </v-list-item>

          <v-list-item to="/talent/dashboard">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item to="/talent/sessions">
            <v-list-item-icon>
              <v-icon>mdi-offer</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Talents</v-list-item-title>
          </v-list-item>

          <v-list-item to="/talent/availability">
            <v-list-item-icon>
              <v-icon>mdi-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Availability</v-list-item-title>
          </v-list-item>

          <v-list-item to="/talent/appointments">
            <v-list-item-icon>
              <v-icon>mdi-calendar-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Appointments</v-list-item-title>
          </v-list-item>

          <v-list-item to="/talent/profile">
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item to="/account/settings">
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item> -->

          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <!-- Loggedin as client-->
        <v-list v-else-if="isLoggedIn && !isTalent">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item @click="searchModal()">
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Search</v-list-item-title>
          </v-list-item>

          <v-list-item to="/upcoming-sessions">
            <v-list-item-icon>
              <v-icon>mdi-monitor-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sessions</v-list-item-title>
          </v-list-item>

          <v-list-item to="/account/settings">
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <!-- Not logged in -->
        <v-list v-else-if="!isLoggedIn">
          <v-list-item @click="loginModal()">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('login_form_title') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-system-bar>
    <v-row v-if="pageTitle">
      <v-col cols="12">
        <v-card class="mx-auto" color="transparent" tile elevation="0">
          <h1>{{ pageTitle }}</h1>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// component
import NotificationButton from '@/components/generic/NotificationButton.vue'

export default {
  name: 'AppHeader',
  components: {
    NotificationButton,
  },
  data() {
    return {
      color: 'primary',
      colors: ['primary', 'blue', 'success', 'red', 'teal'],
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: false,
      background: false,
      appBarColor: 'background: transparent !important',
      text: '',
      isHidden: false,
      menu: false,
      profileIcon:
        'https://meetwithanyone-assets.s3.amazonaws.com/defaultProfileImg.svg',
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'isTalent',
      'pageTitle',
      'profileImage',
      'isAdmin',
      'isRegisterCompleted',
      'notifications',
      'isNotificationRead',
      'user',
    ]),
    isMdAndAbove() {
      return ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)
    },
    image() {
      return this.profileImage ?? this.profileIcon
    },
  },
  mounted() {},
  watch: {},
  created() {
    if (this.isTalent && !this.isRegisterCompleted) this.logout()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    /**
     * HandleScroll
     */
    handleScroll() {
      if (window.scrollY > 10) {
        this.appBarColor = 'background: #F1F3F6 !important'
      } else if (window.scrollY == 0) {
        this.appBarColor = 'background: transparent !important'
      }
    },

    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch('logout')
      window.location = '/'
    },

    /**
     * Open Login Modal
     */
    loginModal() {
      this.$store.commit('loginModal', true)
    },

    /**
     * Open Search Modal
     */
    searchModal() {
      this.$store.commit('searchModal', true)
    },

    /**
     * Close Login Modal
     */
    closeLoginModal() {
      this.$store.commit('loginModal', false)
    },

    /**
     * Close Search Modal
     */
    closeSearchModal() {
      this.$store.commit('searchModal', false)
    },
  },
}
</script>

<style>
</style>

<template>
<v-container class="py-0 fill-height dashboard-container" fluid>
  <v-row justify="center" align="center">
    <!-- eslint-disable-next-line -->
    <v-dialog v-model="dialog" persistent :width="width">
      <v-toolbar flat color="primary">
        <v-icon>mdi-database-search</v-icon>
        <v-toolbar-title class="font-weight-light">
          Search Hosts
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" fab small @click="closeSearchModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card color="white">
        <v-card-text class="text--primary text-center py-2">
              <v-autocomplete
                v-model="talent"
                class="auto-complete mobile-auto-complete"
                :items="searchedTalents"
                placeholder="Start typing to Search"
                tile
                solo
                hide-details
                :hide-no-data="search && search.length ? false : true"
                clearable
                color="black"
                background-color="white"
                item-text="name"
                item-value="slug"
                :search-input.sync="search"
                @input="toProfile"
                @click:clear="clearSearch()"
                @focus="clearSearch()"
              >
                <template #item="data" class="mt-4">
                  <v-list-item-avatar tile size="30" rounded>
                    <v-img
                      :src="
                        data.item.profilePic
                          ? data.item.profilePic
                          : $defaultImg
                      "
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1">
                      {{ data.item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text-subtitle-2 light-text text-wrap"
                    >
                      {{ data.item.tagLine }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</v-container>
</template>
<script>

import { mapGetters } from 'vuex'

// graphql
import { SEARCH_TALENT } from '@/graphql/talent'

export default {
  name: 'Login',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 600,
    },
  },
  data() {
    return {
      descriptionLimit: 60,
      isLoading: false,
      search: null,
      searchedTalents: [],
      talent: null,
      error: null,
    }
  },
  computed: {
    ...mapGetters(['isTalent', 'jwt', 'user']),
  },

  methods: {
    // Clear Search
    clearSearch() {
      this.search = null
      this.talent = null
      this.searchedTalents = []
    },

    // Close Search Modal
    closeSearchModal() {
      this.$store.commit('searchModal', false)
    },

    // Redirect to talent profile page
    toProfile(slug) {
      this.$nextTick(() => {
        this.search = null
        this.talent = null
        this.closeSearchModal()
      })
      this.searchedTalents = []
      if (slug) this.$router.push(`/talent/profile/${slug}`)
    },

    // Search Talent
    async searchTalent(keyword) {
      if (keyword) {
        const {
          data: { searchTalent },
        } = await this.$apollo.query({
          query: SEARCH_TALENT,
          variables: {
            searchInput: {
              name: keyword,
              pagination: {
                first: 0,
                skip: 0,
              },
            },
          },
        })

        if (searchTalent.talentList) {
          // Prepare object for searchbox
          this.searchedTalents = searchTalent.talentList.map((t) => {
            return {
              name: `${t.user.firstName} ${t.user.lastName}`,
              slug: t.user.username,
              id: t.user.id,
              profilePic: t.user.profileImg ? t.user.profileImg : null,
              tagLine: t.shortIntro,
            }
          })
        } else {
          this.searchedTalents = []
        }
      }
    },
  },
  watch: {
    search(val) {
      if (!val) {
        this.clearSearch()
        return
      }
      this.searchTalent(val)
    },
  },
}
</script>

<style scoped>
.v-application .white--text {
    color: black !important;
    caret-color: black !important;
}
</style>

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import App from './app.vue'
import router from './utils/router'
import store from './utils/store'
import i18n from './utils/i18n'
import { createProvider } from './utils/vue-apollo'

import '@/utils/init'
import '@mdi/font/css/materialdesignicons.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './plugins/filters.js'

import device from 'vue-device-detector'

import VueFullscreen from 'vue-fullscreen';
Vue.use(VueFullscreen);

// import VueIsMobile from "vue-is-mobile"
// Vue.use(VueIsMobile)

Vue.config.productionTip = false

Vue.use(Vuetify)
Vue.use(device)

//changed pusher key to backend one
Vue.use(require('vue-pusher'), {
  api_key: '5bceb468ae084ef94a6e',
  options: {
    cluster: 'mt1',
    encrypted: true,
    authEndpoint: 'https://ravenew.byrana.xyz/pusher_auth',
  },
})

const vuetify = new Vuetify({
  // customVariables: ['~/scss/variables.scss'],
  treeShake: true,
  icons: {
    iconfont: 'mdi',
  },
  options: {
    customProperties: true,
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#F7B500',
        secondary: '#F7B500',
        orange: '#FF4200',
        black: '#000',
        white: '#FFF',
        grey: '#f8f9fb',
        light_grey: '#F7F8FB',
        dark_grey: '#E6EAF0',
        yellow: '#F7B500',
        light_error: '#FFD4D4',
        mediumseagreen: '#00C57C',
        grey_medium: '#EAEEF4',
        grey_regular: '#F1F3F6',
        blue_spindle: '#C1CEE0',
      },
    },
  },
})

new Vue({
  created() {
    AOS.init({ disable: 'phone' })
  },
  i18n,
  router,
  store,
  apolloProvider: createProvider(),
  vuetify,
  render: h => h(App),
}).$mount('#app')

import { find, get, isNull } from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'
//import createPersistedState from 'vizir-vuex-persistedstate'

import DejaVue from '@/utils/dejaVue'
import { createProvider } from '@/utils/vue-apollo'
// graphql
import {
  GENERATE,
  LOGIN,
  RESET,
  LOGIN_WITH_GOOGLE,
  REVOKE_TOKEN,
  GET_USER_INFO,
} from '@/graphql/authentication'
import { MARK_NOTIFICATION_READ, DISMISS_NOTIFICATION } from '@/graphql/account'

Vue.use(Vuex)
const apollo = createProvider().defaultClient
const LOCAL_STORAGE_NAME = 'store'

const DEMO_TAG = 'system:demo'
const POTENTIAL_TALENT_TAG = 'potential_talent'

const verifyPrincipal = hydratedState => {
  // if (!hydratedState.principal || !hydratedState.jwt) {
  //   localStorage.clear()
  //   window.location = '/'
  // }
}

const getTimeAgoDate = date => {
  return Vue.prototype
    .$moment(Vue.prototype.$moment(date).toISOString())
    .tz(Vue.prototype.$moment.tz.guess(Boolean))
    .fromNow()
}

const computedDateFormatted = (date, dateFormat) => {
  return Vue.prototype.$moment(date).format(dateFormat)
}

export default new Vuex.Store({
  plugins: [
    DejaVue.plugin('init', LOCAL_STORAGE_NAME),
    (...args) => {
      // console.log(args)
    },
  ],
  state: {
    jwt: null,
    member: {},
    user: {},
    pageTitle: '',
    principal: {},
    tenantId: 1007,
    profile: {},
    signUpModal: false,
    loginModal: false,
    searchModal: false,
    forgotPasswordModal: false,
    profileImage: null,
    notifications: [],
    isNotificationRead: false,
    userFinish: false,
    token: null,
    wbEndDate: '',
    wbHostRoomUrl: '',
    wbMeetingId: '',
    wbRoomName: '',
    wbRoomUrl: '',
  },
  mutations: {
    pageTitle(state, payload) {
      state.pageTitle = payload
    },
    init: DejaVue.mutation(LOCAL_STORAGE_NAME, verifyPrincipal),

    async setProfile(state, profile) {
      if (!profile || Object.keys(profile).length === 0) {
        state.profile = null
      } else {
        const id = get(profile, 'id', null)
        const attrs = get(profile, 'value', {})
        state.profile = { ...state.profile, ...attrs, id, ...profile }
      }
    },
    forgotPasswordModal(state, payload) {
      state.forgotPasswordModal = payload
    },
    loginModal(state, payload) {
      state.loginModal = payload
    },
    searchModal(state, payload) {
      state.searchModal = payload
    },
    logout(state) {
      ;(state.user = {}), (state.jwt = null)
    },
    markNotificationAsRead(state, payload) {
      state.isNotificationRead = payload
    },
    setContactEmail(state, email) {
      state.member.contacts[0].emails[0].email = email
    },
    setIsTalent(state, istalent) {
      state.user.istalent = istalent
    },
    setJwt(state, jwt) {
      state.jwt = jwt
    },
    setMember(state, member) {
      state.member = { ...state.member, ...member }
    },
    setPrincipal(state, principal) {
      state.principal = principal
    },
    setProfileImage(state, profileImage) {
      state.profileImage = profileImage
      state.user.profileImg = profileImage
    },
    setUser(state, user) {
      state.user = user
      state.profileImage = user.profileImg
    },
    setUserNotifications(state, notifications) {
      state.notifications = notifications
    },
    signupModal(state, payload) {
      state.signUpModal = payload
    },
    setUsername(state, username) {
      state.user.username = username
    },
    setUserFinish(state, userfinish) {
      state.userFinish = userfinish
    },
    wbEndDate(state, value) {
      state.wbEndDate = value
    },
    wbHostRoomUrl(state, url) {
      state.wbHostRoomUrl = url
    },
    wbMeetingId(state, value) {
      state.wbMeetingId = value
    },
    wbRoomName(state, value) {
      state.wbRoomName = value
    },
    wbRoomUrl(state, url) {
      state.wbRoomUrl = url
    },
  },
  actions: {
    async login({ commit, dispatch }, input) {
      return await apollo.mutate({
        mutation: LOGIN,
        variables: { loginData: input },
      })
    },
    async markNotificationRead({ commit, state }, input) {
      const {
        data: { markNotificationRead },
      } = await apollo.mutate({
        mutation: MARK_NOTIFICATION_READ,
        variables: {
          notificationData: {
            ...input,
            email: state.user.email,
            accesstoken: state.jwt,
          },
        },
      })
      if (markNotificationRead.success) {
        if (markNotificationRead.unreadNotifications == 0) {
          await commit('markNotificationAsRead', true)
        }
      }
    },
    async dismissNotification({ commit, getters, state }, input) {
      const {
        data: { dismissNotification },
      } = await apollo.mutate({
        mutation: DISMISS_NOTIFICATION,
        variables: {
          notificationData: {
            ...input,
            email: state.user.email,
            accesstoken: state.jwt,
          },
          tzinfo: Vue.prototype.$defaultTZ,
        },
      })
      // const {getters, commit} = Vue.prototype.$myStore;
      if (dismissNotification.success) {
        const notifications = []
        dismissNotification.notifications.forEach(notification => {
          if (getters.isTalent) {
            if (notification.action === 'session_booked') {
              notifications.push({
                createdOn: notification.createdOn,
                id: notification.id,
                read: notification.read,
                // eslint-disable-next-line
                message: `Congratulations! Your session <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.bookedSession.sessionType.title
                }</span> is booked by <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.user.firstName
                }</span> for <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                  `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                  'dddd MMMM DD, YYYY hh:mm a'
                )}</span>`,
              })
            } else if (notification.action === 'session_updated') {
              notifications.push({
                createdOn: notification.createdOn,
                id: notification.id,
                read: notification.read,
                // eslint-disable-next-line
                message: `Session <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.bookedSession.sessionType.title
                }</span> with <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.user.firstName
                }</span> is now scheduled for <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                  `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                  'dddd MMMM DD, YYYY hh:mm a'
                )}</span>`,
              })
            } else if (notification.action === 'session_starting') {
              notifications.push({
                createdOn: notification.createdOn,
                id: notification.id,
                read: notification.read,
                // eslint-disable-next-line
                message: `Your appointment with <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.user.firstName
                }</span> will start at <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                  `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                  'dddd MMMM DD, YYYY hh:mm a'
                )}</span>`,
              })
            } else if (notification.action === 'session_extended') {
              notifications.push({
                createdOn: notification.createdOn,
                id: notification.id,
                read: notification.read,
                // eslint-disable-next-line
                message: `Congratulations! Session <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.bookedSession.sessionType.title
                }</span> is extented by <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.user.firstName
                }</span> to at <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                  `${notification.bookedSession.date} ${notification.bookedSession.endTime}`,
                  'dddd MMMM DD, YYYY hh:mm a'
                )}</span>`,
              })
            }
          } else {
            if (notification.action === 'session_updated') {
              notifications.push({
                createdOn: notification.createdOn,
                id: notification.id,
                read: notification.read,
                // eslint-disable-next-line
                message: `your session  <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.bookedSession.sessionType.title
                }</span> with <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.host.firstName
                }</span> is now scheduled for <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                  `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                  'dddd MMMM DD, YYYY hh:mm a'
                )}</span>`,
              })
            } else if (notification.action === 'session_starting') {
              notifications.push({
                createdOn: notification.createdOn,
                id: notification.id,
                read: notification.read,
                // eslint-disable-next-line
                message: `<span class="text-subtitle-1 pr-1 black--text notifications">${
                  notification.bookedSession.sessionType.title
                }</span> session with <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.host.firstName
                }</span> will start at <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                  `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                  'dddd MMMM DD, YYYY hh:mm a'
                )}</span>`,
              })
            } else if (notification.action === 'session_booked') {
              notifications.push({
                createdOn: notification.createdOn,
                id: notification.id,
                read: notification.read,
                // eslint-disable-next-line
                message: `Congratulations! Your session <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.bookedSession.sessionType.title
                }</span> is booked with <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.host.firstName
                }</span> for <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                  `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                  'dddd MMMM DD, YYYY hh:mm a'
                )}</span>`,
              })
            } else if (notification.action === 'session_extended') {
              notifications.push({
                createdOn: notification.createdOn,
                id: notification.id,
                read: notification.read,
                // eslint-disable-next-line
                message: `Congratulations! Your session <span class="text-subtitle-1 pl-1 black--text notifications">${
                  notification.bookedSession.sessionType.title
                }</span> is extented to at <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                  `${notification.bookedSession.date} ${notification.bookedSession.endTime}`,
                  'dddd MMMM DD, YYYY hh:mm a'
                )}</span>`,
              })
            }
          }
        })

        if (dismissNotification.unreadNotifications == 0) {
          await commit('markNotificationAsRead', true)
        } else {
          await commit('markNotificationAsRead', false)
        }
        await commit('setUserNotifications', notifications)
      }
    },
    async getUser({ commit, state }, input) {
      const {
        data: { getUserInfo },
      } = await apollo.query({
        query: GET_USER_INFO,
        variables: { email: state.user.email },
      })

      if (getUserInfo.success) {
        // getUserInfo.user.istalent = state.user.istalent
        //await commit('setProfileImage', getUserInfo.user.profileImg)
        //await commit('setUser', getUserInfo.user)
      }
    },
    async loginWithGoogle({ commit, dispatch }, input) {
      return await apollo.mutate({
        mutation: LOGIN_WITH_GOOGLE,
        variables: { googleData: input },
      })
    },
    generate(_, input) {
      return apollo.mutate({ mutation: GENERATE, variables: { input } })
    },
    async logout({ commit, state }) {
      await apollo.mutate({
        mutation: REVOKE_TOKEN,
        variables: {
          tokenData: {
            email: state.user.email,
            accesstoken: state.jwt,
          },
        },
      })
      localStorage.removeItem(LOCAL_STORAGE_NAME)
      await commit('logout')
    },
    
    resetPassword(_, input) {
      return apollo.mutate({
        mutation: RESET,
        variables: { input },
      })
    },
    setWbEndDate({ commit }, value) {
      commit('wbEndDate', value)
    },
    setWbHostRoomUrl({ commit }, url) {
      commit('wbHostRoomUrl', url)
    },
    setWbMeetingId({ commit }, value) {
      commit('wbMeetingId', value)
    },
    setWbRoomName({ commit }, value) {
      commit('wbRoomName', value)
    },
    setWbRoomUrl({ commit }, url) {
      commit('wbRoomUrl', url)
    },
  },
  getters: {
    pageTitle: state => {
      return state.pageTitle
    },
    getWbEndDate: state => {
      return state.wbEndDate
    },    
    getWbHostRoomUrl: state => {
      return state.wbHostRoomUrl
    },    
    getWbMeetingId: state => {
      return state.wbMeetingId
    },    
    getWbRoomName: state => {
      return state.wbRoomName
    },    
    getWbRoomUrl: state => {
      return state.wbRoomUrl
    },    
    user: state => {
      return state.user
    },
    notifications: state => {
      return state.notifications
    },
    isNotificationRead: state => {
      return state.isNotificationRead
    },
    profileImage: state => {
      return (
        state.profileImage ?? require('@/assets/images/defaultProfileImg.svg')
      )
    },
    jwt: state => {
      return state.jwt
    },
    cooldown: (state, getters) => {
      return getters.isDemo ? 0 : 300 // in seconds
    },
    cooldownInMinutes: (state, getters) => {
      return getters.isDemo ? 0 : 300 / 60 // in minutes
    },
    hasClaimed: state => !!state.member.claimedOn,
    hasPayout: state => !!get(state, 'member.integrations', []).length,
    isDemo: state => !!find(state.member.tags, t => t === DEMO_TAG),
    isLoggedIn: state => !!state.jwt,
    isTalent: state => !!state.user.istalent,
    isAdmin: state => !!state.user.isstaff,
    isRegisterCompleted: state => !!state.user.didFinishRegistration,
    isPotentialTalent: state =>
      !!find(state.member.tags, t => t === POTENTIAL_TALENT_TAG),
    hasCompletedProfileBasics: state => {
      const { profile } = state

      if (!profile) {
        return null
      }

      const { bio, welcomeMessage, profilePic, category } = profile

      return bio && welcomeMessage && profilePic && category
    },
  },
})

<template>
  <v-menu
    offset-y
    class="flex-column"
    max-width="500"
    min-width="500"
    left
    :nudge-left="30"
    :nudge-bottom="5"
    :close-on-content-click="true"
  >
    <template #activator="{ on, attrs }">
      <v-badge
        :color="
          !isNotificationRead && notifications.length > 0
            ? 'red'
            : 'transparent'
        "
        :dot="!isNotificationRead && notifications.length > 0"
        overlap
        offset-x="45"
        offset-y="15"
      >
        <v-btn
          v-bind="attrs"
          color="primary"
          min-width="30"
          height="47"
          x-small
          :ripple="false"
          class="rounded mr-8"
          v-on="$route.name === 'Notifications' ? false : on"
        >
          <v-icon dark color="black">
            mdi-bell-outline
          </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-list class="py-0">
      <v-card color="white" elevation="15">
        <v-card-title
          style="background-color: #f7b500 !important;
    color: white;"
          class="text-h5"
        >
          {{ $t('notifications') }}
        </v-card-title>
      </v-card>
      <div v-if="notifications && notifications.length > 0">
        <template v-for="(notification, index) in notifications">
          <div :key="notification.id">
            <!-- <v-list-item
              :class="{'mt-n2' : index != 0}"
            >
              <span class="text-body-1 text--opacity-50">{{ getTimeAgoDate(notification.createdOn) }}</span>
            </v-list-item> -->
            <v-list-item
              :class="{ 'mt-n1': index != 0 }"
              @click="markNotificationRead(notification.id)"
            >
              <v-list-item-avatar
                color="grey_regular mr-2"
                class="rounded"
                size="50"
              >
                <span class="text-body-1 text--opacity-50">{{
                  getTimeAgoDate(notification.createdOn)
                }}</span>
                <!-- <v-icon color="black">
                  mdi-clock-time-five-outline
                </v-icon> -->
              </v-list-item-avatar>
              <v-list-item-content class="mt-n1">
                <v-list-item-title
                  class="text-subtitle-2 text--opacity-75 text-wrap"
                  v-html="`${notification.message}`"
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click.stop="dismissNotification(notification.id)">
                  <v-icon color="black">mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="grey" />
          </div>
        </template>
      </div>
      <v-card v-if="notifications.length == 0" color="white" flat tile>
        <v-card-text class="text-subtitle-1 black--text text-center">
          {{ $t('no_notification') }}
        </v-card-text>
      </v-card>
      <v-btn
        v-if="notifications && notifications.length"
        color="white"
        block
        :ripple="false"
        class="rounded mt-4"
        depressed
        to="/notifications"
      >
        {{ $t('all_notification') }}
      </v-btn>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isActive: true,
      routerName: null,
      toggleBtnText: '',
    }
  },
  computed: {
    ...mapGetters(['notifications', 'user', 'jwt', 'isNotificationRead']),
    getTimeAgoDate() {
      return date =>
        this.$moment(this.$moment(date).toISOString())
          .tz(this.$moment.tz.guess(Boolean))
          .fromNow()
    },
  },
  methods: {
    async markNotificationRead(id) {
      await await this.$store.dispatch('markNotificationRead', {
        notificationId: id,
        markall: false,
      })
    },
    async dismissNotification(id) {
      await await this.$store.dispatch('dismissNotification', {
        notificationId: id,
      })
    },
  },
}
</script>

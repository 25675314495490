import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation ChangePasswordMutation($loginData: LoginInput!) {
    userLogin(loginData: $loginData) {
      success
      accesstoken
      istalent
      isstaff
      user {
        id
        username
        firstName
        lastName
        email
        phoneNumber
        profileImg
        verifiedEmail
        zipcode
        city
        gcalLinked
        didFinishRegistration
      }
      language {
        id
        name
        localName
      }
      timezone {
        id
        name
      }
      timezone {
        id
        name
      }
      country {
        id
        code
        name
        enabled
      }
      error
    }
  }
`

export const GET_USER_INFO = gql`
  query DisplayUserSettingOutput($email: String!) {
    getUserInfo(email: $email) {
      success
      user {
        id
        username
        firstName
        lastName
        email
        verifiedEmail
        profileImg
        phoneNumber
        phoneCode
        zipcode
        city
        gcalLinked
        payoutEnabled
        didFinishRegistration
      }
      error
    }
  }
`

export const LOGIN_WITH_GOOGLE = gql`
  mutation GoogleLoginMutation($googleData: GoogleInput!) {
    googleLogin(googleData: $googleData) {
      success
      error
      user {
        id
        username
        firstName
        lastName
        email
        verifiedEmail
        profileImg
        phoneNumber
        zipcode
        city
      }
      istalent
      accesstoken
    }
  }
`
export const REVOKE_TOKEN = gql`
  mutation RevokeTokenMutation($tokenData: TokenInput!) {
    revokeToken(tokenData: $tokenData) {
      success
      error
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation PasswordResetRequestMutation($email: String!) {
    requestResetPassword(email: $email) {
      success
      error
      verificationId
    }
  }
`

export const RESEND_PASSWORD_RESET = gql`
  mutation ResendPasswordResetMutation($codeId: ID!) {
    resendPasswordReset(codeId: $codeId) {
      success
      error
    }
  }
`

export const NEW_PASSWORD = gql`
  mutation PasswordResetMutation($resetInput: PasswordResetInput!) {
    resetPassword(resetInput: $resetInput) {
      success
      error
    }
  }
`

export const EMAIL_ACTIVATION = gql`
  mutation EmailActivationMutation($activationInput: EmailActivationInput!) {
    emailActivate(activationInput: $activationInput) {
      success
      error
    }
  }
`

export const RESEND_EMAIL_ACTIVATION = gql`
  mutation ResendEmailVerficationMutation($emailInput: UserEmailInput!) {
    resendEmailActivate(emailInput: $emailInput) {
      success
      error
    }
  }
`

export const CLAIM = gql`
  mutation consumeOneTimeToken($input: ConsumeOneTimeTokenInput!) {
    consumeOneTimeToken(input: $input)
  }
`

export const GENERATE = gql`
  mutation generateOneTimeToken($input: GenerateOneTimeTokenInput!) {
    generateOneTimeToken(input: $input)
  }
`

export const RESET = gql`
  mutation resetOneTimeToken($input: ResetOneTimeTokenInput!) {
    resetOneTimeToken(input: $input)
  }
`

export const GET_PRINCIPAL = gql`
  query principal {
    getPrincipal {
      role
      displayName
      username
      identityId
      tenantId
      permissions
      member {
        id
        name
        firstName
        lastName
        displayName
        mrn
        contacts {
          id
          emails {
            id
            email
          }
        }
        profileUrl
        createdOn
        joinedOn
        claimedOn
        timezoneId
        languageId
        legalLocaleId
        slugs(priority: 0) {
          slug
        }
        type {
          key
          name
        }
        status
        birthdate
        tags
        subscriptions
      }
    }
  }
`

export const CHECK_EARLY_ACCESS = gql`
  mutation CheckEarlyAccessMutation($registrationCode: String!) {
    checkEarlyAccess(registrationCode: $registrationCode) {
      success
      error
    }
  }
`

export const IMPERSONATE = gql`
  mutation Impersonate(
    $input: IamImpersonationRequest!
    $filter: MemberIntegrationFilter
  ) {
    iamImpersonate(input: $input) {
      token
      success
      reason
      principal {
        username
        identityId
        memberId
        permissions {
          id
          name
          key
        }
        member {
          birthdate
          claimedOn
          contacts {
            emails {
              email
            }
          }
          createdOn
          displayName
          firstName
          id
          integrations(filter: $filter) {
            id
            integration {
              id
              name
              key
            }
          }
          joinedOn
          languageId
          lastName
          legalLocaleId
          mrn
          name
          profileUrl
          timezoneId
          slug
          status
          tags
        }
      }
    }
  }
`

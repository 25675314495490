import { render, staticRenderFns } from "./SearchTalents.vue?vue&type=template&id=3fbef663&scoped=true&"
import script from "./SearchTalents.vue?vue&type=script&lang=js&"
export * from "./SearchTalents.vue?vue&type=script&lang=js&"
import style0 from "./SearchTalents.vue?vue&type=style&index=0&id=3fbef663&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fbef663",
  null
  
)

export default component.exports
import Vue from 'vue'
import moment from 'moment-timezone'
import GAuth from 'vue-google-oauth2'
import Toasted from 'vue-toasted'
import store from '@/utils/store'

import Header from '@/components/layouts/Header.vue'
import Login from '@/components/auth/Login.vue'
import ForgotPassword from '@/components/auth/ForgotPassword.vue'
import LoadingOverlay from '@/components/generic/LoadingOverlay.vue'
import AlertBox from '@/components/generic/AlertBox.vue'
import VueMask from 'v-mask'
import { getNotificationslist } from '@/utils/helpers.js'

// Vue Toaster
Vue.use(Toasted, {
  duration: 5000,
  theme: 'bubble',
})

// Google Auth
const gauthOption = {
  // clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  clientId:
    '951957364416-2blktm2vs52ucrtfttapt486inr4d2a3.apps.googleusercontent.com',
  scope:
    'profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
  prompt: 'consent',
  fetch_basic_profile: true,
}
Vue.use(GAuth, gauthOption)
Vue.use(VueMask)

// Put Components on global Vue object
Vue.component('Header', Header)
Vue.component('Login', Login)
Vue.component('ForgotPassword', ForgotPassword)
Vue.component('LoadingOverlay', LoadingOverlay)
Vue.component('AlertBox', AlertBox)

const plugin = {
  install(vue) {
    Vue.prototype.$setupPusherEvent = () => {
      const { getters } = vue.prototype.$myStore
      if (getters.isLoggedIn) {
        console.log('getters started')
        var sessionBooked = Vue.prototype.$pusher.subscribe(getters.user.email)
        sessionBooked.bind('session_booked', async data => {
          console.log('session_booked', data)
          await getNotificationslist({
            email: getters.user.email,
            token: getters.jwt,
            pageNumber: 4,
            skip: 0,
            notiForheader: true,
          })
        })

        var sessionBooked = Vue.prototype.$pusher.subscribe(getters.user.email)
        sessionBooked.bind('session_starting', async data => {
          console.log('session_starting', data)
          await getNotificationslist({
            email: getters.user.email,
            token: getters.jwt,
            pageNumber: 4,
            skip: 0,
            notiForheader: true,
          })
        })

        var sessionBooked = Vue.prototype.$pusher.subscribe(getters.user.email)
        sessionBooked.bind('session_extended', async data => {
          console.log('session_extended', data)
          await getNotificationslist({
            email: getters.user.email,
            token: getters.jwt,
            pageNumber: 4,
            skip: 0,
            notiForheader: true,
          })
        })
      }
    }
  },
}

Vue.use(plugin)

Vue.prototype.$moment = moment
Vue.prototype.$defaultImg = require('@/assets/images/defaultProfileImg.svg')
Vue.prototype.$defaultTZ = moment.tz.guess(Boolean)
Vue.prototype.$loading = false
Vue.prototype.$gauthOption = gauthOption
Vue.prototype.$myStore = store
